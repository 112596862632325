import { t } from 'i18next';
import React, { useState } from 'react'

export default function ContactUsDesktop() {
    const [name, setName] = useState('');
    const [lastName, setLastName] = useState('');
    const [phoneNumber, setPhoneNumber] = useState('');
    const [email, setEmail] = useState('');
    const [message, setMessage] = useState('');

    return (
        <div className='w-screen h-[1000px] mt-24 flex-col'>
            <div className="text-white text-[24px] mt-4 text-center lg:text-left lg:mt-32 lg:mb-16 lg:ml-[9%] font-extrabold font-['Montserrat'] leading-normal tracking-tight">{t('contactus')}</div>
            <div className='flex justify-center items-center flex-row w-screen h-[500px]'>
                <div className='w-screen h-[500px] flex justify-center items-center mt-[125px]'>
                    <div className='w-9/12 h-[650px] bg-white flex flex-row justify-start items-center'>
                        <div className='w-[490px] h-[550px] bg-[#1A1A1A] ml-12 flex flex-col justify-start items-start px-8 py-8'>
                            <div className="text-white text-[32px] font-extrabold font-['Montserrat']">{t('contactTitle')}</div>
                            <div className='flex flex-row justify-start items-center mt-8'>
                                <svg className='mx-4' width="24" height="25" viewBox="0 0 24 25" fill="none" xmlns="http://www.w3.org/2000/svg">
                                    <g id="bxs:phone-call">
                                        <path id="Vector" d="M20.0002 11.227H22.0002C22.0002 6.09703 18.1272 2.22803 12.9902 2.22803V4.22803C17.0522 4.22803 20.0002 7.17103 20.0002 11.227Z" fill="white" />
                                        <path id="Vector_2" d="M12.9998 8.22802C15.1028 8.22802 15.9998 9.12502 15.9998 11.228H17.9998C17.9998 8.00302 16.2248 6.22802 12.9998 6.22802V8.22802ZM16.4218 13.671C16.2296 13.4964 15.9771 13.4032 15.7176 13.4113C15.4581 13.4193 15.2118 13.5278 15.0308 13.714L12.6378 16.175C12.0618 16.065 10.9038 15.704 9.71179 14.515C8.51979 13.322 8.15879 12.161 8.05179 11.589L10.5108 9.19502C10.6972 9.01415 10.8059 8.76784 10.814 8.50823C10.822 8.24861 10.7287 7.99606 10.5538 7.80402L6.85879 3.74102C6.68384 3.54838 6.44067 3.43153 6.18095 3.41528C5.92122 3.39904 5.66539 3.48468 5.46779 3.65402L3.29779 5.51502C3.1249 5.68854 3.02171 5.91947 3.00779 6.16402C2.99279 6.41402 2.70679 12.336 7.29879 16.93C11.3048 20.935 16.3228 21.228 17.7048 21.228C17.9068 21.228 18.0308 21.222 18.0638 21.22C18.3083 21.2063 18.5391 21.1027 18.7118 20.929L20.5718 18.758C20.7413 18.5606 20.8271 18.3048 20.811 18.0451C20.795 17.7854 20.6783 17.5421 20.4858 17.367L16.4218 13.671Z" fill="white" />
                                    </g>
                                </svg>
                                <div className="text-white text-base font-normal font-['Montserrat']">0312 466 76 70</div>
                            </div>
                            <div className='flex flex-row justify-start items-center mt-8'>
                                <svg className='mx-4' width="24" height="25" viewBox="0 0 24 25" fill="none" xmlns="http://www.w3.org/2000/svg">
                                    <g id="bxs:phone-call">
                                        <path id="Vector" d="M20.0002 11.227H22.0002C22.0002 6.09703 18.1272 2.22803 12.9902 2.22803V4.22803C17.0522 4.22803 20.0002 7.17103 20.0002 11.227Z" fill="white" />
                                        <path id="Vector_2" d="M12.9998 8.22802C15.1028 8.22802 15.9998 9.12502 15.9998 11.228H17.9998C17.9998 8.00302 16.2248 6.22802 12.9998 6.22802V8.22802ZM16.4218 13.671C16.2296 13.4964 15.9771 13.4032 15.7176 13.4113C15.4581 13.4193 15.2118 13.5278 15.0308 13.714L12.6378 16.175C12.0618 16.065 10.9038 15.704 9.71179 14.515C8.51979 13.322 8.15879 12.161 8.05179 11.589L10.5108 9.19502C10.6972 9.01415 10.8059 8.76784 10.814 8.50823C10.822 8.24861 10.7287 7.99606 10.5538 7.80402L6.85879 3.74102C6.68384 3.54838 6.44067 3.43153 6.18095 3.41528C5.92122 3.39904 5.66539 3.48468 5.46779 3.65402L3.29779 5.51502C3.1249 5.68854 3.02171 5.91947 3.00779 6.16402C2.99279 6.41402 2.70679 12.336 7.29879 16.93C11.3048 20.935 16.3228 21.228 17.7048 21.228C17.9068 21.228 18.0308 21.222 18.0638 21.22C18.3083 21.2063 18.5391 21.1027 18.7118 20.929L20.5718 18.758C20.7413 18.5606 20.8271 18.3048 20.811 18.0451C20.795 17.7854 20.6783 17.5421 20.4858 17.367L16.4218 13.671Z" fill="white" />
                                    </g>
                                </svg>
                                <div className="text-white text-base font-normal font-['Montserrat']">0536 597 58 37</div>
                            </div>
                            <div className='flex flex-row justify-center items-center mt-8'>
                                <svg className='mx-4' width="24" height="25" viewBox="0 0 24 25" fill="none" xmlns="http://www.w3.org/2000/svg">
                                    <g id="ic:sharp-email">
                                        <path id="Vector" d="M22 4.22803H2V20.228H22V4.22803ZM20 8.22803L12 13.228L4 8.22803V6.22803L12 11.228L20 6.22803V8.22803Z" fill="white" />
                                    </g>
                                </svg>
                                <div className="text-white text-base font-normal font-['Montserrat']">info@innovapaslanmaz.com.tr</div>
                            </div>
                            <div className='flex flex-row justify-center items-center mt-8'>
                                <svg className='mx-4' width="24" height="25" viewBox="0 0 24 25" fill="none" xmlns="http://www.w3.org/2000/svg">
                                    <g id="carbon:location-filled">
                                        <path id="Vector" d="M12 1.72803C9.81276 1.73061 7.71584 2.60063 6.16923 4.14725C4.62261 5.69386 3.75259 7.79078 3.75001 9.97803C3.74739 11.7654 4.33124 13.5044 5.41201 14.928C5.41201 14.928 5.63701 15.2243 5.67376 15.267L12 22.728L18.3293 15.2633C18.3623 15.2235 18.588 14.928 18.588 14.928L18.5888 14.9258C19.669 13.5027 20.2526 11.7646 20.25 9.97803C20.2474 7.79078 19.3774 5.69386 17.8308 4.14725C16.2842 2.60063 14.1873 1.73061 12 1.72803ZM12 12.978C11.4067 12.978 10.8266 12.8021 10.3333 12.4724C9.83995 12.1428 9.45543 11.6743 9.22837 11.1261C9.00131 10.5779 8.9419 9.9747 9.05765 9.39276C9.17341 8.81081 9.45913 8.27626 9.87869 7.85671C10.2982 7.43715 10.8328 7.15143 11.4147 7.03567C11.9967 6.91992 12.5999 6.97933 13.1481 7.20639C13.6962 7.43345 14.1648 7.81797 14.4944 8.31132C14.8241 8.80466 15 9.38468 15 9.97803C14.999 10.7734 14.6826 11.5359 14.1202 12.0983C13.5578 12.6606 12.7954 12.977 12 12.978Z" fill="white" />
                                    </g>
                                </svg>
                                <div className="text-white text-base font-normal font-['Montserrat']">Farabi Sokak No:3/4 Çankaya/Ankara</div>
                            </div>
                        </div>
                        <div className='flex w-full justify-center items-start flex-col ml-8 mt-6'>
                            <div className="text-black text-base font-medium font-['Montserrat'] leading-tight">{t('contactName')}</div>
                            <input
                                className='w-[50%] h-[40px] mt-1 border-b-2 py-2 px-2 text-black font-["Montserrat"]'
                                type="text"
                                value={name}
                                onChange={(e) => setName(e.target.value)}
                            />
                            <div className="text-black text-base mt-2 font-medium font-['Montserrat'] leading-tight">{t('contactSurname')}</div>
                            <input
                                className='w-[50%] h-[40px] mt-1 border-b-2 py-2 px-2 text-black font-["Montserrat"]'
                                type="text"
                                value={lastName}
                                onChange={(e) => setLastName(e.target.value)}
                            />
                            <div className="text-black text-base mt-2 font-medium font-['Montserrat'] leading-tight">{t('contactEmail')}</div>
                            <input
                                className='w-[50%] h-[40px] mt-1 border-b-2 py-2 px-2 text-black font-["Montserrat"]'
                                type="text"
                                value={email}
                                onChange={(e) => setEmail(e.target.value)}
                            />
                            <div className="text-black text-base mt-2 font-medium font-['Montserrat'] leading-tight">{t('contactPhone')}</div>
                            <input
                                className='w-[50%] h-[40px] mt-1 border-b-2 py-2 px-2 text-black font-["Montserrat"]'
                                type="text"
                                value={phoneNumber}
                                onChange={(e) => setPhoneNumber(e.target.value)}
                            />
                            <div className="text-black text-base mt-2 font-medium font-['Montserrat'] leading-tight">{t('contactMessage')}</div>
                            <input
                                className='w-[50%] h-[40px] mt-1 border-b-2 py-2 px-2 text-black font-["Montserrat"]'
                                type="text"
                                value={message}
                                onChange={(e) => setMessage(e.target.value)}
                            />
                            <div className='flex justify-end items-center w-11/12 mt-8 cursor-pointer'>
                                <div className="w-[278px] h-10 px-[30.73px] py-[9.60px] bg-zinc-900 shadow justify-center items-center gap-[6.40px] inline-flex">
                                    <div className="text-center text-white text-[12.95px] font-medium font-['Montserrat']">{t('send')}</div>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    )
}
