import React from 'react'
import ResponsiveNavbar from './components/ResponsiveNavbar'
import HomepageContent from './components/HomepageContent'
import AboutUs from './components/AboutUs'
import { Hidden } from '@mui/material'
import ProductsMobile from './components/ProductsMobile'
import ProductsDesktop from './components/ProductsDesktop'
import ContactUsMobile from './components/ContactUsMobile'
import FooterMobile from './components/FooterMobile'
import ContactUsDesktop from './components/ContactUsDesktop'
import FooterDesktop from './components/FooterDesktop'

export default function Test() {
    return (
        <div className='bg-[#282828] w-screen h-full flex justify-start items-center flex-col'>
            <ResponsiveNavbar />
            <HomepageContent />
            <AboutUs />
            <Hidden smUp>
                <ProductsMobile />
            </Hidden>
            <Hidden lgDown>
                <ProductsDesktop />
            </Hidden>
            <Hidden smUp>
                <ContactUsMobile />
            </Hidden>
            <Hidden smUp>
                <FooterMobile />
            </Hidden>
            <Hidden lgDown>
                <ContactUsDesktop />
            </Hidden>
            <Hidden lgDown>
                <FooterDesktop />
            </Hidden>
        </div>
    )
}
