import {
  BrowserRouter,
  Route,
  Routes
} from 'react-router-dom';
import Test from './routes/Test/Test';
import Catalog from './routes/Test/Catalog';
import { useTranslation } from 'react-i18next';

export default function App() {
  const { t } = useTranslation();
  return (
    <BrowserRouter>
      <Routes>
        <Route path='/' element={<Test />} />
        <Route path='/katalog' element={<Catalog />} />
        <Route path='/test2' element={<div>Test2</div>} />
      </Routes>
    </BrowserRouter>
  )
}