import { Grid, List, Slide, Typography } from '@mui/material';
import { t } from 'i18next';
import React from 'react'

export default function ProductsMobile() {

    const products = [
        {
            id: 0,
            name: t('product1Title'),
            description: t('product1Description'),
            img: '/images/merdiven.png'
        },
        {
            id: 1,
            name: t('product2Title'),
            description: t('product2Description'),
            img: '/images/pencere.png'
        },
        {
            id: 2,
            name: t('product3Title'),
            description: t('product3Description'),
            img: '/images/bahce.png'
        },
        {
            id: 3,
            name: t('product4Title'),
            description: t('product4Description'),
            img: '/images/balkon.png'
        },
    ];



    return (
        <div className='w-screen h-[2000px] bg-[#282828]'>
            <div className="text-white text-[24px] mt-4 text-center lg:text-left lg:my-16 lg:ml-44 font-extrabold font-['Montserrat'] leading-normal tracking-tight">{t('products')}</div>
            <div className='flex justify-center items-center bg-[#282828] flex-col h-[1750px] overflow-scroll'>
                {products.map((product) =>
                    <div key={product.id} className='w-[300px] h-[400px] mt-8 flex justify-start items-center flex-col bg-white'>
                        <img
                            loading='lazy'
                            className="w-full h-[175px] object-cover"
                            src={product.img} />
                        <div className='w-full h-[120px] px-4 py-2 mt-2'>
                            <div className='text-[18px] font-["Montserrat"] font-bold'>{product.name}</div>
                            <div className='text-[14px] font-["Montserrat"] mt-2'>{product.description}</div>
                        </div>
                    </div>
                )}
            </div>
        </div>
    )
}
