import { t } from 'i18next'
import React from 'react'

export default function FooterDesktop() {
    return (
        <div className='w-screen flex flex-col items-end justify-end'>
            <div className='flex justify-between items-center w-screen h-[200px] flex-row'>
                <img
                    loading='lazy'
                    onClick={() => window.scrollTo(0, 0)}
                    className="w-7/12 h-[100px] cursor-pointer mt-4 relative right-[18%] object-contain"
                    src="/images/innova-logo.png" />
                <div className='relative right-[5%]'>
                    <div
                        onClick={() => window.scrollTo(0, 0)}
                        className="w-[139px] cursor-pointer h-12 px-8 py-2.5 justify-center items-center gap-2.5 inline-flex">
                        <div className="text-white text-sm font-bold font-['Montserrat'] leading-7 tracking-tight">{t('homepage')}</div>
                    </div>
                    <div
                        onClick={() => window.scrollTo(700, 700)}
                        className="w-[139px] cursor-pointer h-12 px-8 py-2.5 justify-center items-center gap-2.5 inline-flex">
                        <div className="text-white text-sm font-bold font-['Montserrat'] leading-7 tracking-tight">{t('about')}</div>
                    </div>
                    <div
                        onClick={() => window.scrollTo(1650, 1650)}
                        className="w-[139px] cursor-pointer h-12 px-8 py-2.5 justify-center items-center gap-2.5 inline-flex">
                        <div className="text-white text-sm font-bold font-['Montserrat'] leading-7 tracking-tight">{t('products')}</div>
                    </div>
                    <div
                        onClick={() => window.scrollTo(2750, 2750)}
                        className="w-[139px] cursor-pointer h-12 px-8 py-2.5 justify-center items-center gap-2.5 inline-flex">
                        <div className="text-white text-sm font-bold font-['Montserrat'] leading-7 tracking-tight">{t('contact')}</div>
                    </div>
                </div>
            </div>
            <div className="text-white text-sm font-normal relative right-8 bottom-4 self-end font-['Montserrat'] leading-7 tracking-tight">{t('footer')}</div>
        </div>
    )
}
