import { t } from 'i18next';
import React from 'react'

export default function ProductsDesktop() {

    const products = [
        {
            id: 0,
            name: t('product1Title'),
            description: t('product1Description'),
            img: '/images/merdiven.png'
        },
        {
            id: 1,
            name: t('product2Title'),
            description: t('product2Description'),
            img: '/images/pencere.png'
        },
        {
            id: 2,
            name: t('product3Title'),
            description: t('product3Description'),
            img: '/images/bahce.png'
        },
        {
            id: 3,
            name: t('product4Title'),
            description: t('product4Description'),
            img: '/images/balkon.png'
        },
        {
            id: 4,
            name: t('product5Title'),
            description: t('product5Description'),
            img: '/images/kis-bahcesi.jpg'
        },
    ];

    return (
        <div className='w-screen h-[1500px] mt-24 flex-col'>
            <div className="text-white text-[24px] mt-4 text-center lg:text-left lg:my-16 lg:ml-[9%] font-extrabold font-['Montserrat'] leading-normal tracking-tight">{t('products')}</div>
            <div className='w-screen h-[500px] flex justify-center items-center bg-[#282828] mt-[300px]'>
                <div>
                    <div className="grid grid-cols-2 gap-4 bg-[#282828]">
                        {products.map((item, index) => (
                            <div key={index} className="bg-white w-[700px] h-[475px]">
                                <img
                                    loading='lazy'
                                    className="w-full h-[310px] object-cover"
                                    src={item.img} />
                                <div className='w-full h-[120px] px-4 py-2 mt-4'>
                                    <div className='text-[18px] font-["Montserrat"] font-bold'>{item.name}</div>
                                    <div className='text-[14px] font-["Montserrat"]'>{item.description}</div>
                                </div>
                            </div>
                        ))}
                    </div>
                </div>
            </div>
        </div>
    )
}
