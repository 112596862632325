import { t } from 'i18next'
import React from 'react'

export default function FooterMobile() {
    return (
        <div className='flex justify-start flex-col items-center w-screen h-[400px] bg-[#282828]'>
            <img
                loading='lazy'
                onClick={() => window.scrollTo(0, 0)}
                className="w-6/12 h-[75px] relative bottom-12 object-contain"
                src="/images/innova-logo.png" />
            <div
                onClick={() => window.scrollTo(0, 0)}
                className="w-[139px] h-12 px-8 py-2.5 justify-center items-center gap-2.5 inline-flex">
                <div className="text-white text-sm font-bold font-['Montserrat'] leading-7 tracking-tight">{t('homepage')}</div>
            </div>
            <div
                onClick={() => window.scrollTo(900, 900)}
                className="w-[139px] h-12 px-8 py-2.5 justify-center items-center gap-2.5 inline-flex">
                <div className="text-white text-sm font-bold font-['Montserrat'] leading-7 tracking-tight">{t('about')}</div>
            </div>
            <div
                onClick={() => window.scrollTo(1900, 1900)}
                className="w-[139px] h-12 px-8 py-2.5 justify-center items-center gap-2.5 inline-flex">
                <div className="text-white text-sm font-bold font-['Montserrat'] leading-7 tracking-tight">{t('products')}</div>
            </div>
            <div
                onClick={() => window.scrollTo(3250, 3250)}
                className="w-[139px] h-12 px-8 py-2.5 justify-center items-center gap-2.5 inline-flex">
                <div className="text-white text-sm font-bold font-['Montserrat'] leading-7 tracking-tight">{t('contact')}</div>
            </div>
            <div className="text-white text-xs relative my-8 font-normal font-['Montserrat'] leading-7 tracking-tight">{t('footer')}</div>
        </div>
    )
}
