import React from 'react'
import { useTranslation } from 'react-i18next';

export default function AboutUs() {
    const { t } = useTranslation();
    return (
        <div className='w-screen h-[1000px] lg:h-[600px] mt-16 lg:mt-8'>
            <div className="text-white text-[24px] mt-4 text-center lg:text-left lg:my-16 lg:ml-[9%] font-extrabold font-['Montserrat'] leading-normal tracking-tight">{t('about')}</div>
            <div className='lg:flex lg:flex-row-reverse'>
                <div className='mt-4 flex justify-center items-center'>
                    <img
                        loading='lazy'
                        className="w-[237px] h-[237px] absolute z-10 left-[60px] lg:left-auto lg:right-[5%] lg:mb-[300px] lg:z-10 lg:w-[475px] lg:h-[475px]"
                        src="/images/homepage3.png" />
                    <div className="w-[237px] h-[237px] mt-16 bg-white z-0 lg:w-[475px] lg:h-[475px] lg:absolute lg:right-[2.5%] lg:mb-[300px]" />
                </div>
                <div className='w-screen h-[600px] lg:text-left lg:w-1/2 lg:relative lg:right-[42%] py-4 px-4 lg:mt-0 mt-16 text-center font-["Montserrat"] text-white'>
                    {t('aboutUsTitle')}
                </div>
            </div>
        </div>
    )
}
