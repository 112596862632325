import React from 'react'
import { useTranslation } from 'react-i18next';
import { animateScroll as scroll } from 'react-scroll';

export default function HomepageContent() {
    const { t } = useTranslation();

    const scrollCurrent = (direction: number) => {
        scroll.scrollTo(direction, {
            duration: 1000,  // animasyon süresi
            delay: 1,       // animasyon başlatma gecikmesi
            smooth: 'easeInOutQuart'  // animasyon eğrisi
        });
    }

    return (
        <div className='flex justify-center items-center w-screen h-[750px] mt-6'>
            <div className='flex w-full justify-center items-center flex-col lg:flex-row-reverse lg:justify-around lg:items-center'>
                <div>
                    <svg className='lg:w-[512px] lg:h-[512px] w-[235px] h-[235px] lg:relative lg:left-[64px]' viewBox="0 0 512 512" fill="none" xmlns="http://www.w3.org/2000/svg">
                        <circle cx="256" cy="256" r="256" fill="#FFF" />
                    </svg>
                    <img
                        loading='lazy'
                        className='w-[190px] h-[145px] lg:w-[416px] lg:h-[316px] absolute z-20 bottom-[62.5%] left-[12.5%] lg:left-[56.5%] lg:top-[20%]'
                        src="/images/homepage3.png"
                        alt="homepage3"
                    />
                    <img
                        loading='lazy'
                        className='w-[190px] h-[145px] lg:w-[416px] lg:h-[316px] absolute z-20 bottom-[55%] left-[40%] lg:left-[70%] lg:top-[400px]'
                        src="/images/homepage2.png"
                        alt="homepage2"
                    />
                </div>
                <div className='mt-[100px] lg:mt-[2px] lg:relative lg:right-[64px]'>
                    <div className="w-[400px] text-2xl lg:text-left text-center lg:text-5xl text-white font-extrabold font-['Montserrat']">{t('homepageTitle1')}</div>
                    <div className="w-[400px] my-4 lg:text-left text-gray-200 text-center text-[13px] font-normal font-['Montserrat'] leading-tight tracking-tight">{t('homepageTitle2')}</div>
                    <div className='flex justify-center items-center lg:items-start lg:justify-start relative top-10'>
                        <div
                            onClick={window.innerWidth < 768 ? () => scrollCurrent(4000) : () => scrollCurrent(2750)}
                            className="w-[163px] h-12 px-10 py-2.5 bg-white flex shadow justify-center items-center gap-2.5 cursor-pointer">
                            <div className="text-zinc-900 text-sm font-bold self-center font-['Montserrat'] leading-7 tracking-tight">{t('contactus')}</div>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    )
}
