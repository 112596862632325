import { AppBar, Button, Container, Divider, Drawer, Hidden, IconButton, List, ListItem, ListItemText, Toolbar, Typography, useMediaQuery } from '@mui/material'
import React, { useState } from 'react'
import { useTranslation } from 'react-i18next';
import { animateScroll as scroll } from 'react-scroll';

export default function ResponsiveNavbar() {

    const [renderMobileMenu, setRenderMobileMenu] = useState(false)
    const [mobileOpen, setMobileOpen] = React.useState(false);
    const isMobile = useMediaQuery('(max-width:600px)');

    const handleDrawerToggle = () => {
        setMobileOpen(!mobileOpen);
    };

    const scrollCurrent = (direction: number) => {
        scroll.scrollTo(direction, {
            duration: 1000,  // animasyon süresi
            delay: 1,       // animasyon başlatma gecikmesi
            smooth: 'easeInOutQuart'  // animasyon eğrisi
        });
    }

    const scrollAndClose = (x: number, y: number) => {
        setRenderMobileMenu(false)
        setMobileOpen(false)
        setTimeout(() => {
            window.scrollTo(x, y)
        }, 100)
    };

    const { t, i18n } = useTranslation();

    const changeLanguage = (lng: any) => {
        i18n.changeLanguage(lng);
    };


    const drawer = (
        <div className='bg-[#282828]'>
            <List style={
                {
                    display: 'flex',
                    flexDirection: 'column',
                    justifyContent: 'center',
                    alignItems: 'center',
                    color: 'white',
                    width: '100%',
                }
            }>
                <ListItem
                    onClick={() => scrollAndClose(950, 950)}
                    button>
                    <ListItemText
                        sx={{ fontFamily: 'Montserrat' }}
                        primary={t('about')} />
                </ListItem>
                <ListItem
                    onClick={() => scrollAndClose(1900, 1900)}
                    button>
                    <ListItemText
                        primary={t('products')} />
                </ListItem>
                <ListItem
                    onClick={() => scrollAndClose(3250, 3250)}
                    button>
                    <ListItemText
                        primary={t('contact')} />
                </ListItem>
            </List>
            <Divider />
        </div >
    );



    return (
        <div className='w-full h-[64px] flex justify-between items-center flex-row mt-2'>
            <Hidden smUp>
                <AppBar sx={{ boxShadow: 0, display: 'flex', flexDirection: 'row', justifyContent: 'space-between', alignItems: 'center', width: '90%' }} style={{ backgroundColor: '#282828' }} position="static">
                    <div>
                        <img
                            loading='lazy'
                            className='w-[144px] h-[48px] lg:w-[196px] lg:h-[72px] relative lg:left-10 lg:top-6 object-fill cursor-pointer'
                            src="/images/innova-logo.png"
                            alt="logo"
                        />
                    </div>
                    <Toolbar style={{ backgroundColor: '#282828' }}>
                        {
                            isMobile
                                ? <IconButton
                                    edge="end"
                                    color="inherit"
                                    aria-label="menu"
                                    onClick={handleDrawerToggle}>
                                    <Container
                                        className='cursor-pointer relative left-12'>
                                        <svg width="27" height="27" viewBox="0 0 27 27" fill="none" xmlns="http://www.w3.org/2000/svg">
                                            <g id="ic:round-menu">
                                                <path id="Vector" d="M4.5 20.25H22.5C23.1187 20.25 23.625 19.7437 23.625 19.125C23.625 18.5063 23.1187 18 22.5 18H4.5C3.88125 18 3.375 18.5063 3.375 19.125C3.375 19.7437 3.88125 20.25 4.5 20.25ZM4.5 14.625H22.5C23.1187 14.625 23.625 14.1188 23.625 13.5C23.625 12.8812 23.1187 12.375 22.5 12.375H4.5C3.88125 12.375 3.375 12.8812 3.375 13.5C3.375 14.1188 3.88125 14.625 4.5 14.625ZM3.375 7.875C3.375 8.49375 3.88125 9 4.5 9H22.5C23.1187 9 23.625 8.49375 23.625 7.875C23.625 7.25625 23.1187 6.75 22.5 6.75H4.5C3.88125 6.75 3.375 7.25625 3.375 7.875Z" fill="white" />
                                            </g>
                                        </svg>
                                    </Container>
                                </IconButton>
                                : null
                        }
                        <Hidden smDown>
                            <div className='bg-[#282828]'>
                                <Button
                                    style={{
                                        marginRight: 16,
                                        color: '#282828',
                                        textTransform: 'none',
                                        fontSize: 16,
                                        fontWeight: '700',
                                    }}
                                    color="inherit">
                                    {t('about')}
                                </Button>
                                <Button
                                    style={{
                                        marginRight: 16,
                                        color: '#FFF',
                                        textTransform: 'none',
                                        fontSize: 16,
                                        fontWeight: '700'
                                    }}
                                    color="inherit">
                                    {t('contact')}
                                </Button>
                                <Button
                                    style={{
                                        marginRight: 16,
                                        color: '#FFF',
                                        textTransform: 'none',
                                        fontSize: 16,
                                        fontWeight: '700',
                                    }}
                                    color="inherit">
                                    {t('products')}
                                </Button>
                            </div>
                        </Hidden>
                    </Toolbar>
                </AppBar>
                <nav>
                    <Hidden smUp implementation="css">
                        <Drawer
                            variant="temporary"
                            anchor={'top'}
                            open={mobileOpen}
                            onClose={handleDrawerToggle}
                            style={{ width: 240 }}
                        >
                            {drawer}
                        </Drawer>
                    </Hidden>
                </nav>
            </Hidden>
            <div>
                <Hidden smDown>
                    <div className='flex justify-between items-center w-screen flex-row'>
                        <div>
                            <img
                                loading='lazy'
                                onClick={() => scrollCurrent(0)}
                                className="w-[200px] h-[100px] cursor-pointer mt-12 ml-4 object-contain"
                                src="/images/innova-logo.png" />
                        </div>
                        <div className='flex justify-center items-center flex-row relative'>
                            <Container className='relative lg:top-6 flex justify-center items-center cursor-pointer transition ease-in-out delay-150 hover:scale-110 hover:underline hover:underline-offset-8 decoration-white'>
                                <div className='text-white w-24 text-center text-lg font-bold font-["Montserrat"] leading-7 tracking-tight'>{t('homepage')}</div>
                            </Container>
                            <Container
                                onClick={() => scrollCurrent(850)}
                                className='relative lg:top-6 flex justify-center items-center cursor-pointer transition ease-in-out delay-150 hover:scale-110 hover:underline hover:underline-offset-8 decoration-white'>
                                <div className='text-white w-24 text-center text-lg font-bold font-["Montserrat"] leading-7 tracking-tight'>{t('about')}</div>
                            </Container>
                            <Container
                                onClick={() => scrollCurrent(1600)}
                                className='relative lg:top-6 flex justify-center items-center cursor-pointer transition ease-in-out delay-150 hover:scale-110 hover:underline hover:underline-offset-8 decoration-white'>
                                <div className='text-white w-24 text-center text-lg font-bold font-["Montserrat"] leading-7 tracking-tight'>{t('products')}</div>
                            </Container>
                            <Container
                                onClick={() => scrollCurrent(2750)}
                                className='relative lg:top-6 flex justify-center items-center cursor-pointer transition ease-in-out delay-150 hover:scale-110 hover:underline hover:underline-offset-8 decoration-white'>
                                <div className='text-white w-24 text-center text-lg font-bold font-["Montserrat"] leading-7 tracking-tight'>{t('contact')}</div>
                            </Container>
                            <Container
                                onClick={() => scrollCurrent(2750)}
                                className='relative lg:top-6 flex justify-center items-center'>
                                <button className="w-[150px] h-10 px-6 py-1.5 bg-white shadow justify-center items-center gap-2.5 inline-flex">
                                    <div className="text-zinc-900 text-sm font-bold font-['Montserrat'] leading-7 tracking-tight">{t('contactus')}</div>
                                </button>
                            </Container>
                        </div>
                    </div>
                </Hidden>
            </div>
        </div >
    )
}
